<template>
  <div class="policy-track">
    <div class="top_banner">
      <img src="@/assets/images/news/banner_news_gy.png" alt="" />
      <div class="top_title">
        <h3>坚守教育初心 担当育人使命 <br />晓羊“公益行·爱持续”</h3>
        <!-- <p>
          Adhering to the original intention and undertaking the mission of
          education
          <br />
          For charity with Xiaoyang's love
        </p> -->
      </div>
    </div>
    <div class="dot_area">
      <img src="@/assets/images/news/dots.png" alt="" />
      <img src="@/assets/images/news/dots.png" alt="" />
    </div>
    <ul class="menu_news">
      <li @click="$router.push('/news/group-dynamics')">
        <div class="title">
          <h3>集团动态</h3>
          <!-- <p>Group Dynamics</p> -->
        </div>
      </li>
      <li @click="$router.push('/news/trade-news')">
        <div class="title">
          <h3>行业新闻</h3>
          <!-- <p>Industry News</p> -->
        </div>
      </li>
      <li @click="$router.push('/news/policy-track')">
        <div class="title">
          <h3>政策追踪</h3>
          <!-- <p>Policy Tracking</p> -->
        </div>
      </li>
      <li @click="$router.push('/news/public-benefit')">
        <div class="title">
          <h3 class="color-primary">晓羊公益行</h3>
          <!-- <p>Xiaoyang Charity Line</p> -->
        </div>
      </li>
    </ul>
    <ul class="centent" v-if="lists.length">
      <li
        v-for="(item, index) in lists"
        :key="index"
        @click="goDetail(item.id)"
      >
        <img :src="item.header_image" alt="" />
        <div class="title">
          <span>{{ item.title }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PublicBenefit',
  data() {
    return {
      lists: [],
    }
  },
  created() {
    this.getDataList()
  },
  mounted() {
    this.$store.commit('setPcHeaderIsTransparent', 'transparent')
  },
  methods: {
    getDataList() {
      this.$axios
        .post('/news/list', {
          category_id: 9,
          status: 1,
        })
        .then(res => {
          this.lists = res.list
        })
    },
    goDetail(id) {
      this.$router.push({
        path: '/news/detail',
        query: {
          id: id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
%policy {
  .policy-track {
    .top_banner {
      position: relative;
      img {
        width: 100%;
      }
      .top_title {
        position: absolute;
        left: 0;
        @include px2vw('left', 268);
        width: auto;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        color: $--color-white;
        h3 {
          margin-top: 0;
          @include px2vw('font-size', 52);
          margin-bottom: 0 !important;
        }
        p {
          @include px2vw('font-size', 32);
          margin-bottom: 0;
          @include px2vw('margin-top', 20);
        }
        &:after {
          content: '';
          position: absolute;
          left: -34px;
          top: 50%;
          transform: translateY(-50%);
          height: 100%;
          width: 6px;
          background-color: $--color-white;
        }
      }
    }
    .dot_area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 40%;
      }
    }
    .menu_news {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 100px;
      li {
        text-align: center;
        cursor: pointer;
        h3 {
          margin: 0;
          @include px2vw('padding-left', 80);
          @include px2vw('padding-right', 80);
          @include px2vw('font-size', 36);
          border-right: 1px solid #d8d8d8;
          color: #333;
        }
        p {
          @include px2vw('font-size', 16);
        }
        &:hover h3 {
          color: $--color-primary;
        }
      }
      li:last-child h3 {
        border: none;
      }
    }
    .centent {
      width: 90%;
      max-width: 1320px;
      margin: 0 auto;
      li {
        width: 100%;
        height: 430px;
        position: relative;
        margin-bottom: 50px;
        box-shadow: 0px 25px 55px 0px rgba(142, 142, 142, 0.19);

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .title {
          width: 100%;
          height: 86px;
          position: absolute;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.5);
          span {
            height: 86px;
            line-height: 86px;
            color: $--color-white;
            padding: 0 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
          }
        }
      }
    }
  }
}
.view-pc {
  @extend %policy;
}
.view-mobile {
  @extend %policy;
  .policy-track {
    margin-top: 44px;
    .top_banner {
      .top_title {
        h3 {
          font-size: 18px;
        }
        p {
          font-size: 14px;
        }
      }
    }
    .menu_news {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 30px;
      // overflow-x: scroll;
      li {
        margin-bottom: 10px;
        width: 50%;
        // width: max-content;
        // min-width: 120px;
        h3 {
          font-size: 16px;
          color: #333;
        }
        p {
          font-size: 10px;
        }
      }
    }
    .centent {
      li {
        height: 100%;
        margin-bottom: 30px;
        .title {
          height: 40px;
          line-height: 40px;
          span {
            padding: 0 10px;
            height: 40px;
            line-height: 40px;
          }
        }
      }
    }
  }
}
</style>
